<!--组件----武器星级分布统计-->
<style scoped lang="scss">
  /* 单独定义弹窗样式 */
  /deep/ .c-el-dialog.weapon_star-el-dialog {
    margin-top: 5vh !important;
    max-width: 1000px;
  }
</style>

<template>
  <el-dialog title="武器星级分布" custom-class="c-el-dialog weapon_star-el-dialog" 
    :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
    <echart class="weapon_star_chart" id="weaponStarChart" width="100%" height="750px" :option="starOption"></echart>
  </el-dialog>
</template>

<script>
  import echart from "@/components/Echarts/Index"
  export default {
    name: "weaponStarChart",
    props: {
      weaponId: {
        default: "",
        type: String
      }
    },
    components: {
      echart
    },
    data() {
      return {
        curWeaponId: this.weaponId,
        showDialog: false,
        starOption: {
          
        }
      }
    },
    watch: {
      showDialog(v) {
        if (v) {
          this.curWeaponId = v
          this.showDialog = true
          this.$nextTick(() => {
            this.getOption()
          })
        }
      }
    },
    methods: {
      getOption() {
        this.starOption = this.setOptions()
      },
      setOptions() {
        let xAxisData = []
        let onlineData = []
        for (let i = 25; i > 0; i--) {
          let title = i + "星"
          let item = Math.floor(Math.random() * 25)
          xAxisData.push(title)
          onlineData.push(item)
        }
        let options = {
          title: {
            text: ""
          },
          tooltip: {
            trigger: "axis"
          },
          legend: {
            data: ["武器数量"]
          },
          grid: {
            containLabel:true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          dataZoom: {
            show: false,
            start: 0,
            end: 100
          },
          xAxis: {
            type: "value",
            axisTick: {

            },
            axisLabel: {
              formatter: "{value}"
            }
          },
          yAxis: {
            type: "category",
            boundaryGap: true,
            data: xAxisData
          },
          series: [
            {
              name: "武器数量",
              type: "bar",
              stack: "total",
              barWidth: "50%",
              barMaxWidth: "18px",
              data: onlineData,
              label: {
                show: true,
                position: "right",
                distance: 5,
                color: "#ff6600"
              }
            }
          ]
        }
        return options
      },
      _close() {
        this.showDialog = false
      }
    }
  }
</script>