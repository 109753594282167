<!--组件----玩家详细信息-->
<style scoped lang="scss">
  /* 单独定义弹窗样式 */
  /deep/ .c-el-dialog.gamer-el-dialog {
    margin-top: 5vh !important;
    max-width: 750px;
    .gamer_dialog_form {
      .el-form-item--small {
        margin-bottom: 0;
        .el-form-item__content {
          width: 150px;
        }
      }
    }
    .before_title {
      height: 2rem;
      line-height: 2rem;
      color: #409EFF;
      font-weight: bold;
    }
  }
</style>

<template>
  <el-dialog title="玩家详细数据" custom-class="c-el-dialog gamer-el-dialog" 
    :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
    <el-form :model="gamerData" :inline="true" label-width="100px" size="small" class="gamer_dialog_form">
      <el-form-item label="ID：" prop="playerId">{{ gamerData.playerId }}</el-form-item>
      <el-form-item label="名称：" prop="playerName">{{ gamerData.playerName }}</el-form-item>
      <el-form-item label="等级：" prop="level">{{ gamerData.level }}</el-form-item>
      <el-form-item label="Vip等级：" prop="playerMethod">{{ gamerData.playerMethod }}</el-form-item>
      <el-form-item label="战力" prop="playerCustoms">{{ gamerData.playerCustoms }}</el-form-item>
      <el-form-item label="竞技场排名" prop="duration">{{ gamerData.duration }}</el-form-item>
      <el-form-item label="下线时间" prop="passStar">2019-11-18 16:13:09</el-form-item>
    </el-form>
    <el-row class="before_title">武器：</el-row>
    <el-table
      :data="gamerData && gamerData.weaponData"
      style="width: 100%" border size="mini">
      <el-table-column label="ID" prop="id"></el-table-column>
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="等级" prop="level"></el-table-column>
      <el-table-column label="升阶" prop="degree"></el-table-column>
      <el-table-column label="升星" prop="star"></el-table-column>
      <el-table-column label="战力" prop="military"></el-table-column>
    </el-table>
    <el-row class="before_title">天赋：</el-row>
    <el-table
      :data="gamerData && gamerData.talentData"
      style="width: 100%" border size="mini">
      <el-table-column label="ID" prop="id"></el-table-column>
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="等级" prop="level"></el-table-column>
      <el-table-column label="升阶" prop="degree"></el-table-column>
      <el-table-column label="升星" prop="star"></el-table-column>
      <el-table-column label="战力" prop="military"></el-table-column>
    </el-table>
    <el-row class="before_title">宝甲：</el-row>
    <el-table
      :data="gamerData && gamerData.treasureData"
      style="width: 100%" border size="mini">
      <el-table-column label="ID" prop="id"></el-table-column>
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="等级" prop="level"></el-table-column>
      <el-table-column label="强化" prop="degree"></el-table-column>
      <el-table-column label="升星" prop="star"></el-table-column>
      <el-table-column label="战力" prop="military"></el-table-column>
    </el-table>
    <el-row class="before_title">装备：</el-row>
    <el-table
      :data="gamerData && gamerData.equipData"
      style="width: 100%" border size="mini">
      <el-table-column label="ID" prop="id"></el-table-column>
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="等级" prop="level"></el-table-column>
      <el-table-column label="品质" prop="degree"></el-table-column>
      <el-table-column label="洗练" prop="star"></el-table-column>
      <el-table-column label="战力" prop="military"></el-table-column>
    </el-table>
    <el-row class="before_title">称号：</el-row>
    <el-table
      :data="gamerData && gamerData.designationData"
      style="width: 100%" border size="mini">
      <el-table-column label="ID" prop="id"></el-table-column>
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="等级" prop="level"></el-table-column>
      <el-table-column label="力量" prop="degree"></el-table-column>
      <el-table-column label="体力" prop="star"></el-table-column>
      <el-table-column label="战力" prop="military"></el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
  export default {
    name: "gamerDetail",
    props: {
      gamerId: {
        default: 0,
        type: Number
      }
    },
    data() {
      return {
        curGamerId: this.gamerId,
        showDialog: false,
        gamerData: {}
      }
    },
    watch: {
      gamerId(v) {
        this.curGamerId = v
        this.showDialog = true
        this.getGamerData()
      }
    },
    methods: {
      getGamerData() {
        this.gamerData = {
          playerId: 10001,
          playerName: "大腕宽面一口闷",
          level: 22,
          playerMethod: "主线",
          playerCustoms: 18,
          passStar: 4,
          duration: 5435,
          boss: 450,
          military: 3000,
          weapons: "青铜剑, 双节棍",
          weaponData: [
            { id: 101, name: "青铜剑", level: 5, degree: 8, star: 15, military: 100 },
            { id: 102, name: "超神鞭", level: 2, degree: 1, star: 3, military: 5 },
            { id: 103, name: "双节棍", level: 55, degree: 18, star: 28, military: 1280 },
            { id: null, name: "武器总战力", level: null, degree: null, star: null, military: 1385 }
          ],
          talentData: [
            { id: 201, name: "狂怒", level: 1, degree: 8, star: 15, military: 1 },
            { id: 202, name: "漫游者", level: 10, degree: 88, star: 115, military: 10 },
            { id: null, name: "天赋总战力", level: null, degree: null, star: null, military: 11 }
          ],
          treasureData: [
            { id: 301, name: "幻象之力", level: 1, degree: 8, star: 15, military: 1 },
            { id: 302, name: "灭日战甲", level: 2, degree: 16, star: 24, military: 2 },
            { id: 303, name: "影流之铠", level: 5, degree: 33, star: 39, military: 5 },
            { id: 304, name: "月咏战甲", level: 10, degree: 27, star: 46, military: 10 },
            { id: 305, name: "风魔战甲", level: 23, degree: 51, star: 62, military: 23 },
            { id: null, name: "宝甲总战力", level: null, degree: null, star: null, military: 41 }
          ],
          equipData: [
            { id: 401, name: "白纱", level: 1, degree: 8, star: 15, military: 1 }
          ],
          designationData: [
            { id: 501, name: "贾妮莎", level: 16, degree: 8, star: 33, military: 55 }
          ]
        }
      },
      _close() {
        this.showDialog = false
      }
    }
  }
</script>