<!--组件----武器统计图表数据-->
<style scoped lang="scss">
  /* 单独定义弹窗样式 */
  /deep/ .c-el-dialog.weaponchart-el-dialog {
    margin-top: 15vh !important;
    max-width: 1000px;
    .gamer_dialog_form {
      .el-form-item--small {
        margin-bottom: 0;
        .el-form-item__content {
          width: 150px;
        }
      }
    }
    .before_title {
      height: 2rem;
      line-height: 2rem;
      color: #409EFF;
      font-weight: bold;
    }
  }
</style>

<template>
  <el-dialog title="武器统计" custom-class="c-el-dialog weaponchart-el-dialog" 
    :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
    <echart class="weapon_chart" id="weaponChart" width="100%" height="520px" :option="weaponOption"></echart>
  </el-dialog>
</template>

<script>
  import echart from "@/components/Echarts/Index"
  export default {
    name: "weaponChart",
    props: {
      weaponId: {
        default: "",
        type: String
      }
    },
    components: {
      echart
    },
    data() {
      return {
        curWeaponId: this.weaponId,
        showDialog: false,
        weaponOption: {
          
        }
      }
    },
    watch: {
      showDialog(v) {
        if (v) {
          this.curWeaponId = v
          this.showDialog = true
          this.$nextTick(() => {
            this.getOption()
          })
        }
      }
    },
    methods: {
      getOption() {
        this.weaponOption = this.setOptions()
      },
      setOptions() {
        let options = {
          title: {
            text: this.$root.isPc ? "武器统计" : ""
          },
          tooltip: {
            axisPointer: { // 指示器，鼠标移动有横线
              type: 'cross'
            },
            trigger: "axis",
            formatter: function(params, ticket, callback) {
              var back = ''
              var sName = ''
              for (var i = 0; i < params.length; i++) {
                var item = params[i]
                var res = ''
                var seriesName = item.seriesName
                var value = item.value + (i !== 0 ? "%" : "")
                var marker = item.marker
                sName = item.name
                res = `${marker} ${seriesName}: ${value}`
                back += `${res}<br />`
              }
              back = `${sName}<br />${back}`
              return back
            }
          },
          legend: {
            data: ["拥有武器玩家数量", "拥有武器玩家比例"]
          },
          grid: {
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          dataZoom: {
            show: true,
            start: 0,
            end: 100
          },
          xAxis: {
            type: "category",
            boundaryGap: true,
            data: ["1星", "2星", "3星", "4星", "5星", "6星", "7星"]
          },
          yAxis: [
            {
              name: '数量(人)',
              type: 'value'
            },
            {
              name: '比例(%)',
              nameLocation: 'end',
              type: 'value',
              axisTick: {
              
              },
              axisLabel: {
                formatter: '{value}%'
              }
            }
          ],
          series: [
            {
              name: "拥有武器玩家数量",
              yAxisIndex: 0,
              type: "bar",
              stack: "count",
              barWidth: "20%",
              barMaxWidth: "20px",
              silent: true,
              label: {
                show: true,
                position: "top",
                distance: 5,
                formatter: function(param) {
                  let symboolVal = "人"
                  return param.value + symboolVal
                }
              },
              data: [66, 33, 44, 22, 55, 31, 99]
            },
            {
              name: "拥有武器玩家比例",
              yAxisIndex: 1,
              type: "line",
              stack: "ratio",
              smooth: false,
              label: {
                show: true,
                position: "top",
                distance: 5,
                formatter: function(param) {
                  let symboolVal = "%"
                  return param.value + symboolVal
                }
              },
              data: [8, 74, 0, 55, 48, 44, 29]
            }
          ]
        }
        return options
      },
      _close() {
        this.showDialog = false
      }
    }
  }
</script>