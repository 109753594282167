<style scoped lang="scss">
  .weapon_box {
    .toolbars {
      .otherbar {
        float: right;
        .selectservicer,.searchbtn  {
          display: inline-block;
        }
        .range_search {
          display: inline-block;
          width: 285px;
          .left {
            width: 185px;
          }
          .center {
            width: 15px;
            display: inline-block;
            text-align: center;
          }
          .right {
            width: 85px;
          }
        }
      }
    }
    .weapon_results {
      margin: 5px auto 10px;
      padding-top: 5px;
      background: #ecf5ff;
      border-radius: 2px;
      .el-col {
        margin-bottom: 5px;
      }
    }
  }
</style>

<template>
  <div class="weapon_box">
    <div class="toolbars mrgb5">
      <div class="fl">
        <el-input class="c-el-input mrgr5 mrgb5" size="small" clearable v-model="weaponId" placeholder="输入武器ID">
          <template slot="prepend">武器ID</template>
        </el-input>
        <el-button class="searchbtn mrgb5" type="primary" plain size="small" @click="getWeaponChart()"><i class="fa fa-search"></i> 武器统计</el-button>
      </div>
      <div class="otherbar">
        <div class="range_search mrgr5 mrgb5">
          <el-input class="left" placeholder="开始值" size="small" clearable v-model="listQuery.keywords">
            <template slot="prepend">竞技场排名</template>
          </el-input>
          <span class="center">-</span>
          <el-input class="right" size="small" clearable v-model="listQuery.keywords" placeholder="结束值"></el-input>
        </div>
        <div class="range_search mrgr5 mrgb5">
          <el-input class="left" placeholder="开始值" size="small" clearable v-model="listQuery.keywords">
            <template slot="prepend">玩家等级</template>
          </el-input>
          <span class="center">-</span>
          <el-input class="right" size="small" clearable v-model="listQuery.keywords" placeholder="结束值"></el-input>
        </div>
        <el-input class="c-el-input mrgr5 mrgb5" size="small" clearable v-model="listQuery.keywords" placeholder="输入玩家ID或名称"></el-input>
        <el-button class="searchbtn mrgr10 mrgb5" type="primary" plain size="small" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
      </div>
    </div>

    <div class="clearb"></div>
    <el-row class="weapon_results" :gutter="6">
      <el-col :sm="8" :md="6" :lg="4">
        <el-card shadow="hover" body-style="padding: 10px;">
          玩家总数量<span class="fr colff60 fwb mrgt2">12580</span>
        </el-card>
      </el-col>
      <el-col :sm="8" :md="6" :lg="4">
        <el-card shadow="hover" body-style="padding: 10px;">
          玩家平均等级<span class="fr colff60 fwb mrgt2">25.25</span>
        </el-card>
      </el-col>
      <el-col :sm="8" :md="6" :lg="4">
        <el-card shadow="hover" body-style="padding: 10px;">
          武器平均数<span class="fr colff60 fwb mrgt2">382.58</span>
        </el-card>
      </el-col>
      <el-col :sm="8" :md="6" :lg="4">
        <el-card shadow="hover" body-style="padding: 10px;">
          武器平均等级<span class="fr colff60 fwb mrgt2">25.88</span>
        </el-card>
      </el-col>
      <el-col :sm="8" :md="6" :lg="4">
        <el-card shadow="hover" body-style="padding: 10px;">
          武器平均阶等<span class="fr colff60 fwb mrgt2">88.99</span>
        </el-card>
      </el-col>
      <el-col :sm="8" :md="6" :lg="4">
        <el-card shadow="hover" body-style="padding: 10px;">
          星级数量分布<a href="javascript:void(0)" class="fr colff60 fwb mrgt2" @click="getStarChart()">点击查看</a>
        </el-card>
      </el-col>
    </el-row>
    
    <el-table :data="tableData" style="width: 100%" border size="mini">
      <el-table-column label="ID" prop="id" v-if="$root.isPc"></el-table-column>
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="等级" prop="level" v-if="$root.isPc"></el-table-column>
      <el-table-column label="竞技场排名" prop="arena" v-if="$root.isPc"></el-table-column>
      <el-table-column label="武器" v-if="$root.isPc" width="520">
        <template slot-scope="scope">
          <template v-for="weap in scope.row.weapons">
            <a href="javascript:void(0)" class="dib mrgb5 mrgr5" :key="weap.id" @click="getWeaponChart()"><el-tag plain size="small">{{ weap.name }}<span class="colff60" v-if="weap.star">({{ weap.star }}星)</span></el-tag></a>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="最后下线时间" prop="time" v-if="$root.isPc"></el-table-column>
      <el-table-column label="操作" width="100px">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <el-button type="primary" plain size="mini">操作<i class="el-icon-arrow-down el-icon--right"></i></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="queryDetail(scope.row, scope.$index)">玩家资料</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container" v-if="isPagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
      :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
      layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!--玩家详细信息-->
    <gamer-detail :ref="refGamerDetail" :gamerId="gamerId"></gamer-detail>
    <!--武器统计-->
    <weapon-chart :ref="refWeaponChart" :weaponId="weaponId"></weapon-chart>
    <!--星级分布-->
    <star-chart :ref="refStarChart" :weaponId="weaponId"></star-chart>
  </div>
</template>

<script>
  import GamerDetail from "./components/gamerDetail"
  import WeaponChart from "./components/weaponChart"
  import StarChart from "./components/weaponStarChart"
  export default {
    name: "weapon",
    components: {
      GamerDetail,
      WeaponChart,
      StarChart
    },
    data() {
      return {
        tableData: [],
        listQuery: {
          keyword: null,
          page: 1,
          size: window.$common.appPageSize
        },
        total: null,
        isPagination: false,
        gamerId: null, // 玩家id
        refGamerDetail: "refWeaponToDetail",
        weaponId: null, // 武器id
        refWeaponChart: "refWeaponToWeaponChart",
        refStarChart: "refWeaponToWeaponStarChart"
      }
    },
    mounted() {
      this.getListData()
    },
    methods: {
      handleSizeChange(val) {
        window.$common.fullLoading()
        this.listQuery.size = val
        this.getListData()
      },
      handleCurrentChange(val) {
        window.$common.fullLoading()
        this.listQuery.page = val
        this.getListData()
      },
      getListData() {
        this.tableData = [
          { id: 1000001, name: "后裔", level: 30, vipLevel: 5, military: 12, arena: 3, weapons: [{ id: 101, name: "青铜剑", star: 3 }, { id: 102, name: "双节棍", star: 2 }, { id: 103, name: "菜刀", star: 1 }], time: "2019-11-18 14:30:15" },
          { id: 1000002, name: "天棚", level: 50, vipLevel: 15, military: 22, arena: 4, weapons: [{ id: 201, name: "青龙偃月刀", star: 5 }, { id: 202, name: "独孤九剑", star: 4 }, { id: 203, name: "鳝鱼头刀", star: 3 }], time: "2019-11-19 14:30:15" },
          { id: 1000003, name: "螺丝帽", level: 70, vipLevel: 25, military: 32, arena: 5, weapons: [{ id: 301, name: "流星锤", star: 7 }, { id: 302, name: "凤嘴刀", star: 6 }, { id: 303, name: "龙须钩", star: 5 }], time: "2019-11-20 14:30:15" },
          { id: 1000004, name: "温柔的铁杵", level: 90, vipLevel: 35, military: 42, arena: 6, weapons: [{ id: 302, name: "弹弓", star: 9 }, { id: 303, name: "金钱镖", star: 8 }, { id: 403, name: "青虹剑", star: 7 }], time: "2019-11-21 14:30:15" },
          { id: 1000005, name: "疯狂的土豆丝", level: 60, vipLevel: 45, military: 52, arena: 7, weapons: [{ id: 303, name: "钢指环", star: 11 }, { id: 304, name: "方天画戟", star: 10 }, { id: 503, name: "青虹剑", star: 9 }], time: "2019-11-21 14:30:15" }
        ]
      },
      queryDetail(row, index) {
        this.gamerId = row.id
        this.$refs[this.refGamerDetail].showDialog = true
      },
      getWeaponChart() {
        this.$refs[this.refWeaponChart].showDialog = true
      },
      getStarChart() {
        this.$refs[this.refStarChart].showDialog = true
      },
      _search() {
        this.getListData()
      },
      commonSuccess(val) {
        let tips = val || "操作成功!"
        this.alert(tips).then(() => {
          this._close()
          window.$common.closeFullLoading()
          this.getListData()
        })
      }
    }
  }
</script>